import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { ButtonType } from "./LoginButton";

export const LogoutButton = ({
  text,
  type,
  isLoading,
}: {
  text?: string;
  type?: ButtonType;
  isLoading?: boolean;
}) => {
  const { logout } = useAuth0();

  return (
    <Button
      type={type}
      style={{ borderRadius: "8px" }}
      icon={isLoading ? <LoadingOutlined /> : <LockOutlined />}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      {text}
    </Button>
  );
};

LogoutButton.defaultProps = {
  type: "primary",
  text: "Logout",
};

export default LogoutButton;
