import React from "react";

export const TagCloud = ({ items }: { items: string[] }) => (
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      textAlign: "center",
    }}
  >
    {items.map((p) => (
      <span style={{ padding: "2px 5px", color: "#999" }}>{p}</span>
    ))}
  </div>
);

export default TagCloud;
