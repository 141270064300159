import React from "react";
import { UnlockTwoTone } from "@ant-design/icons";
import authProviders from "../data/providerList";
import { Divider, Space, Result } from "antd";
import UserMenu from "@strive/login-logout";
import TagCloud from "../components/TagCloud";

export const SecureLoginPage = () => (
  <Result
    style={{ maxWidth: 940, width: "100%", margin: "0 auto" }}
    icon={<UnlockTwoTone />}
    title="Secure Logins with Auth0"
    extra={[
      <p className="lead">
        Turnkey integration for secure login and authentication with nearly any
        IAM or OAuth2 provider
      </p>,
      <TagCloud items={authProviders} />,
      <br />,
      <Divider orientation="center">Demo</Divider>,
      <Space>
        <UserMenu demo />
        <UserMenu withButton={false} demo />
        <UserMenu withAvatar={false} demo />
      </Space>,
      <Space>
        <UserMenu />
        <UserMenu withButton={false} />
        <UserMenu withAvatar={false} />
      </Space>,
      <Space>
        <UserMenu
          vertical={false}
          withUser={false}
          withAvatar={false}
          type="link"
        />
        <UserMenu
          vertical={false}
          withUser={false}
          withAvatar={false}
          type="ghost"
        />
        <UserMenu
          vertical={false}
          withUser={false}
          withAvatar={false}
          type="primary"
        />
      </Space>,
      <Space>
        <UserMenu vertical={false} />
        <UserMenu vertical={false} withButton={false} />
      </Space>,
      <Space>
        <UserMenu withAvatar={false} vertical={false} />
      </Space>,
      <Space>
        <UserMenu withAvatar={false} withButton={false} vertical={false} />
      </Space>,
    ]}
  />
);

export default SecureLoginPage;
