import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import { LoadingOutlined, UnlockOutlined } from "@ant-design/icons";
export type ButtonType =
  | "link"
  | "text"
  | "ghost"
  | "primary"
  | "default"
  | "dashed"
  | undefined;
export const LoginButton = ({
  type,
  isLoading,
}: {
  type?: ButtonType;
  isLoading?: boolean;
}) => {
  const { loginWithPopup } = useAuth0();

  return (
    <Button
      type={type}
      style={{ borderRadius: "8px" }}
      onClick={() => loginWithPopup()}
      icon={isLoading ? <LoadingOutlined /> : <UnlockOutlined />}
    >
      Log In
    </Button>
  );
};

LoginButton.defaultProps = {
  type: "primary",
};

export default LoginButton;
