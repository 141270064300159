export const authProviders = [
  `42 School`,
  `Apple`,
  `Atlassian`,
  `Auth0`,
  `Azure Active Directory B2C`,
  `Basecamp`,
  `Battle.net`,
  `Box`,
  `Bungie`,
  `Amazon Cognito`,
  `Coinbase`,
  `Credentials`,
  `Discord`,
  `Dropbox`,
  `Email`,
  `EVE Online`,
  `Facebook`,
  `FACEIT`,
  `Foursquare`,
  `Freshbooks`,
  `FusionAuth`,
  `GitHub`,
  `GitLab`,
  `Google`,
  `IdentityServer4`,
  `Instagram`,
  `Kakao`,
  `LINE`,
  `LinkedIn`,
  `Mailchimp`,
  `Mail.ru`,
  `Medium`,
  `Naver`,
  `Netlify`,
  `Okta`,
  `OneLogin`,
  `Osso`,
  `Reddit`,
  `Salesforce`,
  `Slack`,
  `Spotify`,
  `Strava`,
  `Twitch`,
  `Twitter`,
  `VK`,
  `WordPress.com`,
  `WorkOS`,
  `Yandex`,
  `Zoho`,
  `Zoom`,
];

export default authProviders;
