import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import SecureLoginPage from "./pages/secureLogin";

import "./index.css";
import "antd/dist/antd.css";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_CLIENTID || ""}
      redirectUri={window.location.origin}
    >
      <SecureLoginPage />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
