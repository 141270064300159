import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Popover, Avatar } from "antd";
import LogoutButton from "./LogoutButton";
import LoginButton, { ButtonType } from "./LoginButton";
import "./UserManager.css";
import { LoadingOutlined } from "@ant-design/icons";

interface UserMenuProps {
  withButton?: boolean;
  withAvatar?: boolean;
  withUser?: boolean;
  vertical?: boolean;
  type?: ButtonType;
  demo?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({
  withButton,
  withAvatar,
  withUser,
  vertical,
  type,
  demo,
}) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithPopup,
    logout,
  } = useAuth0();

  const textContent = () => {
    if (isLoading || demo) {
      return {
        heading: "Authenticating",
        subheading: "Logging you in...",
        src: "",
        icon: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      };
    }
    if (!isAuthenticated || !user) {
      return {
        heading: "Hi there.",
        subheading: "Login to continue",
        src: "",
        icon: null,
      };
    }
    return {
      heading: user.nickname,
      subheading: user.email,
      src: user.picture,
      icon: null,
    };
  };

  const { heading, subheading, src, icon } = textContent();

  function handleClick() {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      loginWithPopup();
    }
  }

  const cx = `userCard ${!vertical && "inline"}`;
  const ActionButton = () =>
    isAuthenticated ? (
      <LogoutButton
        isLoading={isLoading || demo}
        type={type}
        text={!withUser ? user?.nickname : undefined}
      />
    ) : (
      <LoginButton isLoading={isLoading || demo} type={type} />
    );
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );
  return (
    <div className="userManager" onClick={handleClick}>
      <div className={cx}>
        {withAvatar && (
          <div className="avatar">
            <Avatar size="large" src={src} icon={icon} />
          </div>
        )}
        {withUser && (
          <div className="userInfo">
            <h2>{heading}</h2>
            <p>{subheading}</p>
          </div>
        )}
        {withButton && (
          <div className="userInfo">
            <Popover content={content} title="Security">
              <ActionButton />
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

UserMenu.defaultProps = {
  withButton: true,
  withAvatar: true,
  withUser: true,
  type: "primary",
  vertical: true,
};

export default UserMenu;
